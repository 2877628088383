<template>
  <component :is="tag" v-if="tag">
    <slot />
  </component>
  <slot v-else />
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    oneAlwaysActive?: boolean
    onlyOneActive?: boolean
    tag?: keyof HTMLElementTagNameMap
  }>(),
  { onlyOneActive: true, tag: undefined }
)

const activeItems = defineModel<string[]>({ default: [] })
const isOnlyOneActive = computed(() => (activeItems.value ?? []).length <= 1)

const MODE_METHODS: Record<
  'one' | 'multiple',
  Record<'add' | 'omit', <T>(arr: T[], value: T) => T[]>
> = {
  one: {
    add: (_, value) => [value],
    omit: () => []
  },
  multiple: {
    add: (arr, value) => [...arr, value],
    omit: (arr, value) => arr.filter(v => v !== value)
  }
}

const handleChangeFactory = (id: string) => (active: boolean) => {
  if (props.oneAlwaysActive && !active && isOnlyOneActive.value) return

  const MODE = props.onlyOneActive ? 'one' : 'multiple'
  const modeMethods = MODE_METHODS[MODE]

  const method = active ? modeMethods.add : modeMethods.omit

  activeItems.value = method(activeItems.value ?? [], id)
}

const isActive = (id: string) => (activeItems.value ?? []).includes(id)

provide('handle-change-factory', handleChangeFactory)
provide('is-active', isActive)
</script>
