<template>
  <button
    :id="id"
    :aria-expanded="isCollapseActive"
    :aria-controls="sectionId"
    @click.prevent="toggleExpanded"
  >
    <slot />
  </button>
</template>

<script lang="ts" setup>
const id = useRequiredInject<string>('collapse-id')
const sectionId = useRequiredInject<string>('section-id')
const isCollapseActive = useRequiredInject<Ref<boolean>>('collapse-active')

const handleChange =
  useRequiredInject<(active: boolean) => void>('handle-change')
const toggleExpanded = () => handleChange(!isCollapseActive.value)
</script>
